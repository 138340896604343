@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

:root {
  --scroll-bg: 255 255 255;
  --h-height: 70px;
  --w-menu: 320px;

  @media (max-width: 1199px) {
    --w-menu: 250px;
  }

  @media (max-width: 991px) {
    --w-menu: 80px;
  }
}

$main-color: #ff5252;
$main-color-green: #00a76f;
$accent: #ff5630;
$font-color: #212b36;
$font-faded: #637381;
$bg-main: #ffffff;
$bg-second: #f4f6f8;
$gray: #f4f6f8;
$light-gray: #8e8e8e;
$green: #00a76f;
$radius: 1em;
$radius-sm: 8px;
$radius-xs: 0.4em;
$transition: 0.3s;

$xl-bp: 1399px;
$lg-bp: 1199px;
$md-bp: 991px;
$sm-bp: 767px;
$xs-bp: 575px;

/* mixins */
@mixin no-appearance {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin disabled-scroll {
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 0px;
    transition: 0.2s;

    &:hover {
      background-color: #999;
    }

    &:active {
      background-color: #999;
    }
  }

  &::-webkit-scrollbar-button:vertical:start:decrement,
  &::-webkit-scrollbar-button:vertical:end:increment,
  &::-webkit-scrollbar-button:horizontal:start:decrement,
  &::-webkit-scrollbar-button:horizontal:end:increment {
    display: none;
  }
}

@mixin custom-scroll {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 5px;
    transition: 0.2s;

    &:hover {
      background-color: #999;
    }

    &:active {
      background-color: #999;
    }
  }

  &::-webkit-scrollbar-button:vertical:start:decrement,
  &::-webkit-scrollbar-button:vertical:end:increment,
  &::-webkit-scrollbar-button:horizontal:start:decrement,
  &::-webkit-scrollbar-button:horizontal:end:increment {
    display: none;
  }
}

.top {
  &-1 {
    top: 10px;
  }

  &-2 {
    top: 15px;
  }

  &-3 {
    top: 20px;
  }

  &-4 {
    top: 25px;
  }

  &-5 {
    top: 30px;
  }
}

a,
a:hover,
a:focus,
a:active {
  color: inherit;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}

p,
label,
span,
a,
form,
fieldset,
legend,
select,
div,
blockquote,
cite,
figure,
figcaption,
table,
ul,
ol,
dl,
li {
  line-height: 150%;
  margin-bottom: 0px;
}

select {
  @include no-appearance();
}

input,
textarea,
select,
.phone-input,
.form-control {
  background: transparent;
  color: $font-color;
  border: 1px solid $gray;
  border-radius: $radius-sm;
  padding: 0.2em 1em;
  height: 42px;
  width: 100%;
  font-size: 15px;
  line-height: normal;
  outline: none;
  display: block;
  transition: $transition;

  &:-moz-placeholder,
  &::-moz-placeholder,
  &::-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    color: $font-faded;
    font-weight: 500;
  }

  &::placeholder {
    color: #919eab;
    font-weight: 500;
  }
}

textarea {
  resize: none;
  min-height: 42px !important;
  height: auto !important;
  padding: 0.8em 1em !important;

  &:focus {
    border-color: $font-color;
  }
}

.input-password-eye {
  position: absolute;
  right: 10px;
  top: 0px;
  display: flex;
  align-items: center;
  bottom: 0px;
}

.input-password {
  input {
    padding-right: 40px;
  }
}

.text-transfer {
  white-space: nowrap;
  overflow: hidden;
  display: block !important;
  text-overflow: ellipsis !important;
}

.wrap {
  overflow-wrap: anywhere;
}

.input-code {
  display: flex;

  input {
    margin-right: 15px;
    text-align: center;
    height: 50px;
    font-size: 20px;
    padding-left: 0px;
    padding-right: 0px;
    font-weight: 600;

    &:last-child {
      margin-right: 0px;
    }
  }
}

.input {
  position: relative;

  input:focus {
    border-color: $font-color;
    background: transparent !important;
  }

  input:read-only {
    cursor: default;
    border-color: $gray;
  }

  .label {
    user-select: none;
    position: absolute;
    top: -9px;
    left: 2px;
    background-color: $bg-main;
    padding: 0px 5px;
    font-size: 11px;
    color: #919eab !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  &.error {

    .label,
    .error-text {
      color: $accent !important;
    }

    input {
      border-color: $accent !important;
    }
  }
}

div:not(.form-switch)>input {
  box-shadow: none !important;

  &[type="number"] {
    @include no-appearance();
  }

  &[type="radio"],
  &[type="checkbox"] {
    @include no-appearance();
    cursor: pointer;
    width: 1.5em !important;
    height: 1.5em !important;
    box-shadow: none !important;
    padding: 0px !important;
    margin: 0px;
    transition: background-color $transition !important;
    background-color: transparent;
    border-color: $font-faded !important;
    border-width: 1px !important;

    &:checked {
      background-color: $main-color-green !important;
      background-image: url(../images/icons/mark.svg) !important;
      background-repeat: no-repeat !important;
      background-position: center center !important;
      background-size: 60% !important;
      border-color: $main-color-green !important;
    }
  }

  &[type="radio"] {
    border-radius: 50% !important;
  }

  &[type="checkbox"] {
    border-radius: $radius-xs !important;
  }
}

.form-switch input {
  cursor: pointer;

  &:focus {
    border-color: rgba(0, 0, 0, 0.25) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e") !important;
    outline: 0;
    box-shadow: none;
  }

  &[type="checkbox"]:checked:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
    outline: 0;
    box-shadow: none;
  }

  &[type="checkbox"]:checked {
    background-color: #00a76f !important;
    border-color: #00a76f !important;
  }
}

.text-left {
  text-align: left !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  @include no-appearance();
}

label {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  span {
    flex: 1;
    margin-left: 0.6em;
  }
}

fieldset {
  legend {
    font-size: 15px;
    font-weight: 500;
  }
}

.input-group {
  flex-wrap: nowrap;

  &>*:not(:first-child) {
    margin-left: 1px !important;
  }
}

.text-success {
  color: #00a76f !important;
}

.text-gray {
  color: #637381 !important;
}

.text-danger {
  color: #ff5252 !important;
}

.status-order {
  &-new {
    @extend .status-order;
    background-color: #00a76f14 !important;
    color: $main-color-green !important;
  }

  &-done {
    @extend .status-order;
    background-color: #00a76f14 !important;
    color: $main-color-green !important;
  }

  &-delivery {
    @extend .status-order;
    background-color: #00b8d929 !important;
    color: #00b8d9 !important;
  }

  &-preparing {
    @extend .status-order;
    background-color: #3366ff29 !important;
    color: #3366ff !important;
  }

  &-prepared {
    @extend .status-order;
    background-color: #ffab0029 !important;
    color: #ffab00 !important;
  }

  &-reservation {
    @extend .status-order;
    background-color: #ab41ff29 !important;
    color: #ab41ff !important;
  }

  &-progress-reservation {
    @extend .status-order;
    background-color: #ab41ff !important;
  }

  &-canceled {
    @extend .status-order;
    background-color: #ff563029 !important;
    color: #ff5630 !important;
  }

  &-processing {
    @extend .status-order;
    background-color: #919eab29 !important;
    color: #222222 !important;
  }
}

button {
  background: none;
  border: none;
  padding: unset;
  display: block;
  font-family: inherit;
  outline: none;
  color: inherit;
}

.btn {
  @include flex-center;
  padding: 0.2em 1em !important;
  height: 42px;
  text-align: center;
  transition: $transition;
  width: fit-content;
  font-size: 0.9em;
  font-weight: 600;
  border: none;
  border-radius: $radius-sm;
  position: relative;
  overflow: hidden;
  background-color: $bg-second;

  &:hover {
    background-color: #e6e7e8;
  }

  &-sm {
    padding: 0.15em 0.8em !important;
    height: 35px;
    font-size: 12px !important;
  }

  &-xs {
    padding: 0.15em 0.8em !important;
    height: 30px;
    font-size: 12px !important;
  }

  &-primary {
    @extend .btn;
    background-color: #4a83dc !important;
    color: #fff !important;

    &:hover,
    &:active {
      opacity: 0.8;
      background-color: #4a83dc !important;
      color: #fff !important;
    }

    &:disabled {
      opacity: 0.6;
      color: $light-gray;
    }
  }

  &-primary-outline {
    @extend .btn;
    background-color: #00a76f1a !important;
    color: $main-color-green !important;


    &:hover,
    &:active {
      background-color: #00a76f2b !important;
      color: $main-color-green !important;
    }

    &:disabled {
      opacity: 0.6;
      color: $light-gray;
    }
  }

  &-tab {
    @extend .btn;
    background-color: #00a76f1a !important;
    color: $main-color-green !important;
    align-items: flex-start;

    h2 {
      white-space: normal; // Позволяет тексту перенестись на новую строку
      word-wrap: break-word; // Переносит слово на новую строку, если оно не помещается
      overflow-wrap: break-word;
      max-width: 100%;

    }


    &:hover,
    &:active,
    &.active {
      background-color: #00a76f2b !important;
      color: $main-color-green !important;
    }

    &:disabled {
      opacity: 0.6;
      color: $light-gray;
    }
  }

  &-blue {
    @extend .btn;
    background-color: #3366ff !important;
    color: #fff !important;

    &:hover,
    &:active {
      background-color: #2d5eee !important;
      color: #fff !important;
    }

    &:disabled {
      opacity: 0.6;
      color: $light-gray;
    }
  }

  &-danger {
    @extend .btn;
    background-color: $accent !important;
    color: #fff !important;

    &:hover,
    &:active {
      background-color: $accent !important;
      color: #fff !important;
    }

    &:disabled {
      opacity: 0.6;
      color: $light-gray;
    }
  }

  &-light {
    @extend .btn;
    background-color: #eee !important;
    color: #000 !important;

    &:hover,
    &:active {
      background-color: #ddd !important;
      color: #000 !important;
    }

    &:disabled {
      opacity: 0.6;
      color: $light-gray;
    }
  }

  &-danger-outline {
    @extend .btn;
    background-color: #e8323214 !important;
    color: $accent !important;

    &:hover,
    &:active {
      background-color: #e8323219 !important;
      color: $accent !important;
    }

    &:disabled {
      opacity: 0.6;
      color: $light-gray;
    }
  }

  &-category-light {
    @extend .btn;
    background-color: #3366ff14 !important;
    color: $font-color !important;
    font-size: small;

    &:hover,
    &:active,
    &.active {
      background-color: #3366ff7a !important;
      color: $font-color !important;
    }

    &:disabled {
      opacity: 0.6;
      color: $light-gray;
    }

    @media (max-width: $md-bp) {
      font-size: 0.8em;
    }
  }

  &-status-order {
    @extend .btn;
    white-space: nowrap;
    min-width: 140px !important;
    padding: 0.6em 1em !important;
    height: auto;
  }
}

.table-responsive {
  overflow-x: auto;
  /* Включаем горизонтальный скролл */
  overflow-y: hidden;
  /* Отключаем вертикальный скролл */
  -webkit-overflow-scrolling: touch;
  /* Для плавного скролла на touch-устройствах */
  position: relative;
  /* Для корректного позиционирования dropdown */



  /* Стили для dropdown */
  .dropdown {
    position: relative;
    /* Относительное позиционирование для контейнера dropdown */
    display: inline-block;
    /* Чтобы dropdown был в строке с другими элементами */
  }

  .dropdown-menu {
    position: fixed !important;
    /* Фиксированное позиционирование для меню */
    z-index: 1000 !important;
    min-width: fit-content;
  }

  /* Показываем dropdown-меню при активации */
  .dropdown.active .dropdown-menu {
    display: block;
  }
}

.role {
  @extend .btn;
  white-space: nowrap;
  padding: 0.4em 1em !important;
  font-size: 12px !important;
  height: auto;
}

.btn-check+.btn {
  background: #919eab3d !important;
  color: $light-gray !important;
}

.btn-check:checked+.btn-primary {
  background-color: #4a83dc !important;
  color: #fff !important;

  &:hover,
  &:active {
    opacity: 0.8;
    background-color: #4a83dc !important;
    color: #fff !important;
  }
}

h1,
.h1 {
  font-size: 2.25em;
  font-weight: 700;
  text-align: left;
  margin-bottom: 1em;

  @media (max-width: $md-bp) {
    font-size: 2em;
  }

  @media (max-width: $xs-bp) {
    font-size: 1.6em;
  }
}

h2 {
  font-size: 1.75em;
  font-weight: 700;
  text-align: left;
  margin-bottom: 1em;

  @media (max-width: $xs-bp) {
    font-size: 1.5em;
  }
}

h3 {
  font-size: 1.25em;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0px;
}

h4 {
  font-size: 1.25em;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0px;
}

h5 {
  font-size: 1.1em;
  font-weight: 600;
  text-align: left;
  margin-bottom: 0px;
}

h6 {
  font-size: 0.8em;
  color: $font-faded;
}

.fs {
  &-07 {
    font-size: 0.7em !important;
  }

  &-08 {
    font-size: 0.8em !important;
  }

  &-09 {
    font-size: 0.9em !important;
  }

  &-11 {
    font-size: 1.1em !important;
  }

  &-12 {
    font-size: 1.2em !important;
  }

  &-13 {
    font-size: 1.3em !important;
  }

  &-14 {
    font-size: 1.4em !important;
  }

  &-15 {
    font-size: 1.5em !important;
  }

  &-16 {
    font-size: 1.6em !important;
  }

  &-17 {
    font-size: 1.7em !important;
  }

  &-18 {
    font-size: 1.8em !important;
  }

  &-19 {
    font-size: 1.9em !important;
  }

  &-20 {
    font-size: 2em !important;
  }

  &-25 {
    font-size: 2.5em !important;
  }

  &-30 {
    font-size: 3em !important;
  }

  &-35 {
    font-size: 3.5em !important;
  }

  &-40 {
    font-size: 4em !important;
  }
}

.fw {
  &-3 {
    font-weight: 300 !important;
  }

  &-4 {
    font-weight: 400 !important;
  }

  &-5 {
    font-weight: 500 !important;
  }

  &-6 {
    font-weight: 600 !important;
  }

  &-7 {
    font-weight: 700 !important;
  }

  &-8 {
    font-weight: 800 !important;
  }

  &-9 {
    font-weight: 900 !important;
  }
}

.main-color {
  color: $main-color;
}

.font-color {
  color: $font-color;
}

.font-faded {
  color: $font-faded;
}

.light-gray {
  color: $light-gray;
}

.green {
  color: $green;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.bb-1 {
  border-bottom: 1px dashed currentColor;
}

table.simple {
  width: 100%;

  td {
    font-weight: 500;
    padding: 0.5em 0;
    line-height: 125%;

    &:first-child {
      color: $font-faded;
      padding-right: 1em;
    }

    &:last-child {
      color: $font-color;
      text-align: right;
    }
  }
}

.card {
  background: $bg-main !important;
  border-radius: $radius !important;
  box-shadow: 0px 0px 2px #919eab1f, 0px 8px 24px -4px #919eab33 !important;
  border: none !important;

  .card-header {
    border: none !important;
    padding: 1em 1.5em;
    background-color: transparent !important;

    &.sticky {
      position: sticky;
      top: 145px;
      z-index: 50;
    }

    .card-header-box {
      padding: 1em 1.5em !important;
      border-bottom: 1px solid $gray !important;
    }
  }

  .card-body {
    padding: 1.5em;
  }

  .card-footer {
    border: none;
    overflow: hidden;
    border-top: 1px solid $gray;
    padding: 1em 1.5em;
    background-color: transparent !important;

    &.sticky {
      position: sticky;
      bottom: 0px;
      background-color: $bg-main !important;
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
      z-index: 50;
    }

    &.pagination a:hover svg:not(.text-muted) {
      color: $main-color-green !important;
    }
  }
}

hr,
.dropdown-divider {
  width: 100% !important;
  border: none !important;
  border-top: 1px solid $gray !important;
  opacity: 1 !important;

  &.dashed {
    border-top: 1px dashed $gray !important;
  }
}

.flex-1 {
  flex: 1;
}

.mb-6 {
  margin-bottom: 6em;

  @media (max-width: $sm-bp) {
    margin-bottom: 5em;
  }

  @media (max-width: $xs-bp) {
    margin-bottom: 4em;
  }
}

@media (max-width: $xs-bp) {
  .px-mobile-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-main;
  color: $font-color;
  font-size: 16px;
  font-weight: 400;
}

header {
  position: sticky;
  z-index: 200;
  top: 0px;

  &.h-fixed {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    display: none;
    z-index: 100;

    &.show {
      display: block;
    }

    @media (max-width: $md-bp) {
      top: var(--h-height);
    }
  }

  .nav-date {
    font-weight: 600;
    color: $font-color !important;
  }

  .language {
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  }

  .account-menu {
    width: 170px;
    padding: 0.2em 0.8em;
  }

  .notification-menu {
    width: 280px;
    padding: 0.2em 0.8em;
  }

  .navbar {
    background-color: $bg-main;
  }

  .navbar,
  nav {
    height: var(--h-height);
    align-items: center;

    .nav-link,
    svg {
      color: $font-faded;
    }

    .nav-link {
      padding-left: 0.7rem !important;
      padding-right: 0.7rem !important;
      position: relative;

      .badge {
        background-color: #ff5630 !important;
        position: absolute;
        top: 2px;
        padding: 3px 5px;
        right: 10px;
      }
    }
  }
}

.select {
  padding: 0.5rem 1rem !important;
  color: #212b36 !important;
  border: 1px solid $gray;
  border-radius: $radius-sm;
  position: relative;
  height: 42px;
  font-size: 15px;

  &-sm {
    padding: 0.15em 0.8em !important;
    height: 35px;
    z-index: 1;
    font-size: 12px !important;
  }

  &-xs {
    padding: 0.15em 0.8em !important;
    height: 30px;
    font-size: 12px !important;
  }

  .select-label {
    user-select: none;
    position: absolute;
    top: -9px;
    left: 2px;
    background-color: $bg-main;
    padding: 0px 5px;
    font-size: 11px;
    color: #919eab !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.dropdown-menu {
  @include custom-scroll();
  border-radius: $radius-sm;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08),
    -20px 20px 40px -4px rgba(0, 0, 0, 0.08);
  border: none;
  z-index: 200 !important;
  padding: 0.5em;
  min-width: 100%;
  max-height: 350px;
  overflow: auto;
  font-size: 15px;

  .dropdown-item {
    color: #212b36;
    padding: 0.4em 0.8em;
    border-radius: $radius-sm;
    margin-bottom: 3px;

    &:last-child {
      margin-bottom: 0px;
    }

    &.disabled {
      color: #999 !important;
    }
  }

  .dropdown-item.active {
    background: #919eab14;
  }

  .dropdown-item:hover {
    background: #919eab20;
  }
}

.search-box {
  align-items: center;
  -webkit-backface-visibility: hidden;
  border-radius: 8px;
  display: flex;
  flex: 1 1;
  flex-direction: row;
  flex-wrap: nowrap;
  isolation: isolate;
  justify-content: center;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.form-search {
  display: flex;
  position: relative;
  z-index: 999;
  align-items: center;

  input {
    flex: 1;
  }
}

main {
  min-height: calc(100vh - 100px);
  padding: 1em 0;
}

/* Profile */
// input[type="date"]::-webkit-calendar-picker-indicator,
// input[type="time"]::-webkit-calendar-picker-indicator,
// input[type="datetime-local"]::-webkit-calendar-picker-indicator {
//   filter: invert(1);
// }

/* Account Menu */
.account {
  &-menu-sub {
    &-bg {
      position: sticky;
      top: 70px;
      z-index: 100;
      max-height: 77px;
      background-color: $bg-main;
      margin-bottom: 15px;
      padding-top: 15px;
    }

    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px dashed $gray;

    ul {
      list-style: none;
      padding-left: 0px;
      display: flex;
      align-items: center;
      padding-bottom: 15px;

      li {
        padding-right: 10px;

        a,
        button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 0.8em 1em;
          position: relative;
          overflow: hidden;
          font-size: 15px;
          font-weight: 500;
          text-align: left;
          color: $font-faded;

          @media (max-width: 575px) {
            font-size: 14px;
            padding: 0.8em;
            padding-right: 0.2em;
          }

          &.active {

            svg.icon path,
            svg.icon rect {
              fill: $main-color-green;
            }

            color: $main-color-green;
            background: rgba(0, 171, 85, 0.08);
            border-radius: $radius-sm;
            font-weight: 600;
          }
        }
      }
    }
  }

  &-menu {
    position: relative;
    z-index: 9999;
    padding: 1em 0px;

    @media (max-width: 991px) {
      padding: 0px;
    }

    .accordion {
      .accordion-item {
        border-bottom: 0px !important;

        .accordion-button {
          font-size: 15px;
          color: $font-faded !important;
          font-weight: 500 !important;

          &:not(.collapsed) {
            background-color: rgba(0, 0, 0, 0.03) !important;
            color: $main-color-green !important;
            color: $font-faded !important;
            border-radius: $radius-sm;
          }

          &::after {
            width: 0.8rem;
            height: 0.8rem;
          }
        }

        .accordion-collapse {
          margin-left: 25px;

          div {
            margin-top: 15px;
            margin-bottom: 15px;
            border-left: 1px solid $gray;
          }

          button,
          a {
            padding: 0.5em 1.4em !important;
            border-radius: 0px !important;
            background-color: transparent !important;
          }
        }
      }
    }

    svg.icon {
      margin-right: 15px;

      path,
      rect {
        fill: $font-faded;
      }
    }

    ul {
      list-style: none;
      padding-left: 0px;

      li {
        .nav-label {
          margin-bottom: 1em;
          margin-left: 20px;
          font-weight: 700;
          font-size: 13px;
          color: $font-faded;
          text-transform: uppercase;
        }

        a,
        button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 0.8em 1em;
          position: relative;
          overflow: hidden;
          font-size: 15px;
          font-weight: 500;
          text-align: left;
          color: $font-faded;

          @media (max-width: 575px) {
            font-size: 14px;
            padding: 0.8em;
            padding-right: 0.2em;
          }

          &.active {

            svg.icon path,
            svg.icon rect {
              fill: $main-color-green;
            }

            color: $main-color-green;
            background: rgba(0, 171, 85, 0.08);
            border-radius: $radius-sm;
            font-weight: 600;
          }
        }

        &:last-child a,
        &:last-child button {
          border: 0px;
        }

        button {
          &::after {
            content: "";
            position: absolute;
            top: 0px;
            left: 1.5em;
            right: 1.5em;
            height: 1px;
            background-color: #dadada;

            @media (max-width: 575px) {
              left: 0px;
              right: 0px;
            }
          }
        }
      }
    }

    .logout::after {
      display: none;
    }
  }

  @media (max-width: 991px) {
    .box-2 {
      box-shadow: none;
      border-radius: unset;
    }
  }
}

.accordion {
  .accordion-button::after {
    width: 1rem;
    height: 1rem;
    background-size: contain;
  }

  .accordion-item {
    border: 0px;
    border-bottom: 1px solid $gray;
    border-radius: 0px;

    &:last-of-type {
      border-bottom: 0px !important;
    }
  }

  .accordion-body {
    font-size: 14px;
    padding: 0.8em 1em;
  }

  .accordion-button {
    padding: 0.8em 1em;
    font-size: 15px;

    &:focus,
    &:not(.collapsed) {
      outline: none !important;
      box-shadow: none !important;
      border-color: inherit !important;
      background-color: transparent !important;
    }

    &:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fillRule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
    }
  }

  .accordion-button:not(.collapsed) {
    color: inherit;
  }
}

.swiper {
  &-button {
    &-disabled {
      display: none;
    }

    &-prev {
      left: 0px;
      background: linear-gradient(to right, $bg-main 20%, transparent);
    }

    &-next {
      right: 0px;
      background: linear-gradient(to left, $bg-main 20%, transparent);
    }

    &-prev,
    &-next {
      top: 0px;
      bottom: 0px;
      width: 8%;
      height: 100%;
      margin-top: 0px;
      color: $font-color;

      &::after {
        font-weight: 800;
        font-size: 1.4em;
      }
    }
  }
}

.swiper-stories {
  .swiper-slide {
    width: 270px;

    @media (max-width: $md-bp) {
      width: 100px;
    }

    @media (max-width: $xs-bp) {
      width: 70px;
    }
  }
}

figure.story {
  position: relative;
  width: 100%;
  border: 1px solid $main-color;
  border-radius: 1.5em;
  padding: 0.5em;
  overflow: hidden;
  cursor: pointer;

  @media (max-width: $md-bp) {
    border: none;
    border-radius: 0px;
    padding: 0px;
  }

  img {
    width: 100%;
    border-radius: 1.25em;
    height: 340px;
    object-fit: cover;

    @media (max-width: $md-bp) {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 1px solid $main-color;
      padding: 4px;
    }

    @media (max-width: $xs-bp) {
      width: 70px;
      height: 70px;
    }
  }

  figcaption {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    bottom: 0.5em;
    right: 0.5em;
    background-image: linear-gradient(180deg,
        rgba(217, 217, 217, 0) 0%,
        rgba(18, 18, 18, 0) 0.01%,
        #121212 100%),
      radial-gradient(ellipse farthest-side at right top, $bg-main, transparent);
    background-position: center bottom, center top;
    background-size: 100% 45%, 100% 30%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1em;

    @media (max-width: $md-bp) {
      position: relative;
      padding: 0px;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      margin-top: 0.5em;

      h3 {
        font-size: 1em;
        font-weight: 400;
        text-align: center;
      }
    }

    @media (max-width: $xs-bp) {
      h3 {
        font-size: 0.9em;
      }
    }
  }
}

.swiper-stories-mobile {
  width: 100%;
  position: relative;

  .swiper-pagination {
    bottom: unset;
    top: 1em;
    padding: 0 1em;
    @include flex-center();

    &-bullet {
      background-color: $light-gray;
      transition: $transition;
      opacity: 1;
      flex-grow: 1;
      height: 3px;
      border-radius: 1px;

      &-active {
        background-color: $font-color;
        opacity: 1;
      }
    }
  }
}

figure.story-large {
  position: relative;
  width: 100%;
  padding: 0px;
  overflow: hidden;

  img {
    width: 100%;
    height: 800px;
    object-fit: cover;

    @media (max-width: $xl-bp) {
      height: 700px;
    }

    @media (max-width: $xs-bp) {
      height: 99vh;
    }
  }

  figcaption {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(to bottom,
        rgba(0, 0, 0, 0.65) 0%,
        transparent 15%,
        transparent 40%,
        $bg-main 100%);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 2em;

    .content {
      display: grid;
      grid-template-columns: auto 110px;
      grid-template-rows: auto 110px;

      @media (max-width: $xs-bp) {
        grid-template-rows: auto auto;
      }

      .info {
        grid-row: 1/2;
        grid-column: 1/2;
      }

      .ingredients {
        grid-row: 2/3;
        grid-column: 1/2;
        padding-right: 1em;

        @media (max-width: $xs-bp) {
          grid-column: 1/3;
        }
      }

      .g-btn {
        grid-row: 2/3;
        grid-column: 2/3;

        @media (max-width: $xs-bp) {
          grid-row: 1/2;
        }

        .btn-primary {
          width: 110px;
          height: 110px;
          border-radius: 50%;
          padding: 0px;
        }
      }
    }
  }
}

.story-modal {
  .modal-dialog {
    max-width: 565px;

    @media (max-width: $xl-bp) {
      max-width: 494px;
    }

    @media (max-width: $xs-bp) {
      max-width: 100%;
    }
  }
}

.swiper-menu {
  background-color: $bg-second;
  padding: 1em;
  border-radius: $radius;

  @media (max-width: $xs-bp) {
    border-radius: 0px;
  }

  .swiper-button-prev {
    background: linear-gradient(to right, $bg-second 60%, transparent);
    width: 4.5em;
  }

  .swiper-button-next {
    background: linear-gradient(to left, $bg-second 60%, transparent);
    width: 4.5em;
  }

  .swiper-slide {
    width: fit-content;
    padding-left: 30px;
    padding-right: 30px;

    button,
    a {
      padding: 0.6em 1.2em;
      border-radius: 10px;
      font-weight: 500;

      &:hover,
      &:focus {
        background-color: $bg-main;
      }

      &:active,
      &.active {
        background-color: $bg-main;
        color: $main-color;
      }
    }
  }
}

footer {
  background: $bg-second;
  height: var(--f-height);

  .dev {
    color: $light-gray;
    font-weight: 500;
    font-size: 0.8em;

    .color {
      color: #b2ff59;
      font-weight: 600;
    }
  }
}

.mobile-nav {
  background: $bg-second;
  height: var(--f-height);

  @media (max-width: $md-bp) {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 100;
    border-radius: $radius $radius 0 0;
  }

  @media (min-width: 992px) {
    display: none;
  }

  //height: 100%;
  width: 100%;

  ul {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    li {
      a {
        @include flex-center();
        font-size: 1.7em;
        width: 1.9em;
        height: 1.9em;
        border: 2px solid transparent;
        border-radius: 50%;
        color: $font-faded;
        transition: $transition;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          bottom: 0px;
          left: 50%;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: transparent;
          transition: $transition;
          transform: translate(-50%, 50%);
        }

        &.active {
          color: $main-color;
          border: 2px solid #303030;

          &::before {
            background-color: $main-color;
          }
        }
      }
    }
  }
}

.text-muted {
  color: #919eab !important;
}

.empty-page {
  height: 100%;
  text-align: center;

  section {
    height: 100%;
  }
}

.modal {
  z-index: 99999;
  padding-left: 0px !important;

  &-backdrop {
    z-index: 99999;
  }

  &-dialog {
    @media (max-width: $xs-bp) {
      margin: 0px !important;
      width: 100%;

      .modal-content {
        border-radius: 0px !important;
      }
    }
  }

  &-content {
    background-color: $bg-main;
    border-radius: $radius;

    @media (max-width: $xs-bp) {
      height: 100%;
    }
  }

  &-header {
    display: block;
    padding: 1.5em 1.8em;
    position: relative;
  }

  &-body {
    position: relative;
    padding: 1.8em;
  }

  .close {
    @include flex-center();
    position: absolute;
    opacity: 1;
    color: rgba(0, 0, 0, 0.2);
    transition: $transition;
    z-index: 100;

    &:hover,
    &:focus {
      opacity: 1;
    }

    right: 0.8em;
    color: rgba(0, 0, 0, 0.2);
    font-size: 1.8em;
    top: 0.8em;

    &:hover {
      color: rgba(0, 0, 0, 0.4);
    }
  }
}

.login {
  display: flex;
  height: 100vh;
  padding: 0px 20px;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;

  &-form {
    width: 370px;
    max-width: 100%;
  }

  &-info {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../images/login-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.offcanvas {
  background-color: $bg-main;

  &.offcanvas-start {
    top: var(--h-height);
    width: 100%;
    z-index: 99999;
  }

  &.offcanvas-top {
    top: var(--h-height);
    height: fit-content;
  }

  &-body {
    padding: 2em 0;
  }
}

.mobile-menu-left {
  font-size: 1.2em;

  a {
    color: $font-color;
    transition: $transition;

    &.active {
      color: $main-color;
    }
  }
}

.phone-input:focus,
.form-control:focus {
  background: $bg-second;
  color: $font-color;
  box-shadow: none;
}

.action-alert {
  position: fixed;
  right: 2vw;
  top: 12vh;
  background-color: #1f1f1f;
  max-width: 300px;
  padding: 15px;
  font-size: 0.8em;
  z-index: 3000;
  border-radius: 10px;
  display: none;
  align-items: center;
  gap: 10px;
  animation: linear;
  animation-name: run;
  animation-duration: 0.2s;

  &.show {
    display: flex;
  }

  // .action-alert__icon
  &__icon {
    width: 20px;
    height: 20px;
    flex: 1 0 auto;
  }

  // .action-alert_success
  &_success {
    .action-alert__icon {
      color: $green;
    }
  }

  // .action-alert_danger
  &_danger {
    .action-alert__icon {
      color: $accent;
    }
  }

  @keyframes run {
    0% {
      right: -300px;
    }

    100% {
      right: 2vw;
    }
  }
}

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999 !important;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.custom-input-street {
  inset: auto !important;
  transform: none !important;
}

.w-fit {
  cursor: pointer;
  width: fit-content !important;
}

.custom-mini-select {
  padding: 8px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  text-align: center;
  font-size: 13px !important;
}

.badge.bg-danger {
  background: rgba(255, 86, 48, 0.16) !important;
  color: #b71d18 !important;
  padding: 5px 8px;
}

.right-content {
  padding-left: var(--w-menu);
  width: 100%;
}

.left-menu {
  display: flex;
  position: fixed;
  padding: 0px 12px;
  border-right: 1px dashed $gray;
  max-width: 320px;
  width: 100%;
  min-width: 320px;
  max-height: 100vh;
  overflow-y: auto;

  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @media (max-width: $lg-bp) {
    max-width: 250px;
    min-width: 250px;
  }

  @media (max-width: $md-bp) {
    max-width: 80px;
    min-width: 80px;

    ul li p,
    ul li a>span>span,
    ul button span span,
    .logo span,
    ul button::after {
      display: none !important;
    }

    ul .accordion-collapse {
      padding-left: 0px !important;
    }

    .logo {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
  }
}

.badge-status-bg {
  background-color: $gray;
  height: 2px;
  position: absolute;
  top: 3px;
  width: 100%;
}

.badge-status {
  &.justify-content-end {
    width: 14px !important;
  }

  &.justify-content-start {
    width: 14px !important;
  }

  background-color: #fff;
  width: 20px;
  position: relative;
  display: flex;
  justify-content: center;

  div {
    background-color: #919eab;
    border-radius: $radius;
    width: 8px;
    height: 8px;
  }
}

.status-progress {
  .status-title {
    font-weight: 600;
    color: #919eab;
  }

  .active {
    .badge-status div {
      background-color: $main-color-green;
    }

    .status-title {
      color: $font-color;
    }
  }

  .col {
    &.active {
      &~div {
        .badge-status div {
          background-color: $main-color-green !important;
        }

        .status-title {
          color: $font-color;
        }
      }
    }
  }
}

.custom-table {
  &.lite {
    box-shadow: none !important;
  }

  .item {
    border-bottom: 1px solid $gray;
  }

  .item:last-child {
    border: none;
  }

  .active {
    background-color: $bg-second !important;
  }

  .desc {
    border-bottom: 1px solid $gray;

    .row {
      border: none;
    }
  }

  .col,
  [class^="col-"] {
    padding: 12px;
    align-items: center;
    display: flex;
    font-size: 13px;

    .btn {
      font-size: 14px;
    }
  }

  &.lite .head,
  .head {
    background-color: $bg-second;
    color: $font-faded;
    font-weight: 600;
    border-bottom: none;

    .col {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &.lite .head {
    border-top-left-radius: $radius-sm;
    border-top-right-radius: $radius-sm;
  }

  .light {
    background-color: transparent !important;
    border-bottom: 1px solid $gray !important;
  }

  a svg {
    color: $font-faded;
  }

  .form-check-input:checked .row {
    background-color: $bg-second;
  }

  .order.status-new {
    background-color: #00a76f14;
  }

  .order.status-reservation {
    background-color: #ab41ff14;
  }
}

.comment {
  padding: 0.5em 1em !important;
  border-radius: $radius-sm;
  background-color: $bg-second;
}

.form-check-label {
  padding-left: 5px;
  font-size: 14px;
}

.hv-100 {
  min-height: calc(100vh - var(--h-height) - 2em);
}

.hv-100-important {
  min-height: 100vh !important;
}

.order-product img {
  border-radius: $radius-sm;
}

.nav-fill {
  border: none;
  background-color: $bg-second;
}

.nav-fill .nav-item {
  .nav-link {
    color: $font-faded;
    border: none;
    border-bottom: 2px solid transparent;
    font-size: 0.9em;
    border-radius: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;
    background-color: transparent;
  }

  .active {
    color: $font-color;
    border-color: $main-color-green !important;
  }
}

.border-left {
  border-left: 1px solid $gray;
}

.border-left-2 {
  border-left: 2px solid $gray;
}

.dialog {
  overflow: hidden;

  .border-right {
    border-right: 1px solid $gray;
  }

  .border-bottom {
    border-bottom: 1px solid $gray !important;
  }

  .chat {
    max-height: fit-content;
    height: calc(100vh - 260px) !important;
  }
}

.chat-container {
  @include disabled-scroll();
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 120px) !important;
}

.online {
  width: 5px;
  min-width: 5px;
  height: 5px;
  min-height: 5px;
  border-radius: 10px;
  background: $green !important;
}

.dialog-new {
  width: 10px;
  min-width: 10px;
  height: 10px;
  min-height: 10px;
  border-radius: 20px;
  background: $accent !important;
}

.dialog {

  &.active,
  &:hover {
    background-color: $bg-second;
  }
}

.dialog-order {
  &:hover {
    background-color: $bg-second;
  }
}

.chat {
  @include custom-scroll();
  padding-right: 10px;
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;

  .text {
    padding: 0.6em 0.8em;
    background-color: $bg-second;
    border-radius: $radius-sm;
    word-break: break-word;

    &.my {
      background-color: #c8facd;
      margin-left: 56px;
      margin-right: 0px;
    }
  }
}

.product-mini {
  user-select: none;
  padding: 0.5em 0.8em !important;
  min-height: 100px;
  border: 1px solid $bg-second;
  border-radius: $radius-sm;
  background-color: $bg-second;

  &.active {
    background-color: #00a76f14;
    border-color: $main-color-green;

    .icon {
      color: $main-color-green !important;
    }
  }

  .title {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    min-height: 65px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.popover {
  border: none;
  box-shadow: 0px 0px 2px #919eab1f, 0px 12px 24px -4px #919eab33 !important;

  &-body {
    padding: 0.8em 1em;
  }
}

.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::before {
  border: none;
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  top: 65px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
}

.notification {
  box-sizing: border-box;
  padding: 13px 20px;
  box-shadow: 0px 0px 2px #919eab1f, 0px 8px 24px -4px #919eab33 !important;
  border-radius: $radius-sm;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  font-size: 0.9em;
  position: relative;
  margin-bottom: 15px;
}

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-exit {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-exit.notification-exit-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

.notification-info {
  color: #2f96b4;
}

.notification-success {
  color: #36b37e;
}

.notification-warning {
  color: #f89406;
}

.notification-error {
  color: #bd362f;
}

.upload-box {
  position: relative;
  background-color: $bg-second;
  border-radius: $radius-sm;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px dashed #919eab52;

  &.product {
    min-height: 280px;
  }

  &.modifier {
    min-height: 100px;
  }

  &.active {
    border: 1px dashed $main-color-green;
    background-color: #00a76f14;
  }
}

.upload-box-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: $radius;
  overflow: hidden;
  pointer-events: none;
  user-select: none;
}

.drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.editor-image {
  position: relative;
  min-height: calc(100vh - 220px);

  @media (max-width: $md-bp) {
    min-height: calc(100vh - 145px);
  }
}

.react-tagsinput {
  background: transparent;
  color: $font-color;
  border: 1px solid $gray;
  border-radius: $radius-sm;
  min-height: 42px;
  padding: 0.5em 1em;
  width: 100%;
  line-height: normal;
  outline: none;
  display: block;
  transition: $transition;
}

.react-tagsinput--focused {
  border-color: $font-color;
}

.react-tagsinput-tag {
  background-color: #919eab29;
  border-radius: $radius;
  display: inline-flex;
  margin-bottom: 5px;
  margin-right: 5px;
  font-size: 0.9em;
  padding: 3px 8px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  margin-left: 4px;
  content: " ×";
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #999;
  outline: none;
  height: 30px;
  padding: 0px;
}

.modal-header {
  position: sticky;
  top: 0;
  border-top-left-radius: $radius-sm;
  border-top-right-radius: $radius-sm;
  background-color: inherit;
  z-index: 1055;
}

.modal-footer {
  position: sticky;
  bottom: 0;
  border-bottom-left-radius: $radius-sm;
  border-bottom-right-radius: $radius-sm;
  background-color: inherit;
  z-index: 1055;
}

a[disabled] {
  opacity: 0.6;
  pointer-events: none !important;
}

.reactEasyCrop_CropArea {
  color: rgba(0, 0, 0, 0.8) !important;
}

.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

.headlineButtonWrapper {
  display: inline-block;
}

.headlineButton {
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.headlineButton:hover,
.headlineButton:focus {
  background: #f3f3f3;
}

.text-nowrap {
  max-width: 100%;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.leaflet-container .leaflet-control-attribution a:first-child,
.leaflet-container .leaflet-control-attribution span {
  display: none !important;
}

.alert {
  font-size: 14px;
}

.alert-success {
  --bs-alert-color: #065e49;
  --bs-alert-bg: #d3fcd2;
  --bs-alert-border-color: #d3fcd2;
}

.bg-success {
  color: #118d57 !important;
  background-color: #22c55e29 !important;
}

.bg-warning {
  color: #ffab00 !important;
  background-color: #ffab0029 !important;
}

.badge {
  display: inline-flex;
  align-items: center;
  padding: 0.4rem 0.65rem !important;

  &-small {
    padding: 0.3rem 0.4rem !important;
    font-size: 0.6em;
  }
}

.bg-primary {
  color: #006c9c !important;
  background-color: #00b8d929 !important;
}

.bg-secondary {
  color: #212b36 !important;
  background-color: #919eab14 !important;
}

.module-category {
  background-color: $bg-second;
  padding-top: 13px;
  padding-bottom: 13px;
}

.module-image {
  object-fit: contain;
  border-radius: $radius-sm;
}

.module-card {
  cursor: pointer;
  position: relative;
  border-radius: $radius-sm;

  &:hover {
    background-color: $bg-second;
  }

  .success {
    color: #fff !important;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 2px;
    height: 24px;
    border: 2px solid #fff;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background-color: $green !important;
  }
}

.payment {
  padding: 0px 15px;
  height: 90px;
  display: flex;
  border-radius: $radius;

  h4 {
    color: #fff;
    font-size: 26px;
    font-weight: bold;
  }

  p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.8rem;
  }

  span {
    font-size: 15px;
    font-weight: 500;
  }

  @media (max-width: $lg-bp) {
    span {
      font-size: 13px;
    }

    img {
      display: none;
    }
  }

  @media (max-width: $sm-bp) {
    span {
      font-size: 14px;
    }
  }

  &.my,
  &.pay {
    background-image: url(../images/icons/rub.svg);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 40%;
  }

  &.my {
    background-color: #007b55;
  }

  &.pay {
    background-color: hsl(198, 100%, 31%);
  }

  &.add {
    background-color: rgba(51, 102, 255, 0.08);
    color: #36f;
  }

  &.list {
    background-color: rgba(0, 171, 85, 0.08);
    color: #00ab55;
  }

  &.service {
    background-color: rgba(0, 184, 217, 0.08);
    color: #00b8d9;
  }
}

.analytics {
  padding: 0px 15px;
  display: flex;
  min-height: 280px;
  border-radius: $radius;

  h4 {
    font-size: 32px;
    font-weight: bold;
  }

  span {
    font-size: 15px;
    font-weight: 500;
    opacity: 0.8;
  }

  &.pay {
    background-color: #3366ff14;
    color: #3366ff;
  }

  &.ads {
    background-color: #cafdf5;
    color: #003768;
  }

  &.deals {
    background-color: #c8facd;
    color: #005249;
  }

  &.tikets {
    background-color: #fff5cc;
    color: #7a4100;
  }
}

.mini-img {
  border-radius: $radius-sm;
  object-fit: contain;
}

.blocked-avatar {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: $radius-sm;
}

.avatar-verified {
  position: absolute;
  right: -3px;
  bottom: -3px;
  color: $green !important;
  background-color: #fff;
  border-radius: 100%;
}

.sale-mini {
  position: relative;
  overflow: hidden !important;
  border-radius: $radius-sm;

  img {
    width: 100%;
    object-fit: cover;
  }

  figcaption {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    font-size: 14px;
    padding: 1em;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.custom-tabs {
  border-radius: $radius-sm;
  overflow: hidden;
  border: none;

  .nav-item .active {
    background-color: $main-color-green;
    color: $bg-main;
  }
}

@mixin flex($dir: row, $x: center, $y: center, $wrap: nowrap) {
  display: flex;
  flex-direction: $dir;
  justify-content: $x;
  align-items: $y;
  flex-wrap: $wrap;
}

@mixin list {
  list-style: none;
  padding-left: 0px;
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin clamp($count, $line, $strict: false) {
  display: -webkit-box;
  -webkit-line-clamp: $count;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: $line;

  @if $strict {
    height: $line * $count;
  }

  @else {
    max-height: $line * $count;
  }
}

/* Articles */
.articles-list {
  @include list();
  margin-top: 1em;

  &-item {
    border: 1px solid $gray;
    border-radius: 10px;
    padding: 1em;
    @include flex(row, flex-start, stretch, nowrap);

    &:not(:first-child) {
      margin-top: 1em;
    }

    img {
      width: 200px;
      height: auto;
      object-fit: contain;
      border-radius: 10px;
      margin-right: 1.25em;
    }

    &-info {
      flex: 1;
      @include flex(column, space-between, flex-start, nowrap);

      .content {
        color: $font-color;
        font-size: 0.9em;
        @include clamp(2, 1.4em, true);
      }

      time {
        display: block;
        color: $font-color;
        font-size: 0.9em;
      }
    }

    &-menu {
      margin-left: 0.5em;

      .dropdown-toggle {
        height: fit-content;
        padding: 0px;
        font-size: 1.75em;
        background-color: transparent;
        color: $font-color;
        transition: $transition;

        &::after {
          content: unset;
        }

        &:hover,
        &:focus {
          color: $font-color;
        }

        &:active {
          color: $font-color;
        }
      }

      .dropdown-item {
        padding: 0px;

        a,
        button {
          width: 100%;
          @include flex(row, space-between, center, nowrap);
          padding: 0.4em 0.8em;
        }
      }
    }
  }
}

/* Articles END */
table,
.tableResponsive {
  width: 100%;

  thead {
    border-width: 1px 0px;
    border-color: $gray;
    border-style: solid;
    font-size: 13px;
    font-weight: 400;
    color: $font-color;
  }

  tbody {
    font-size: 14px;

    tr {
      border-bottom: 1px solid $gray;
    }
  }

  td {
    min-width: 70px;
    padding: 0.5rem 1rem;
    line-height: 120%;

    @media (max-width: $md-bp) {
      padding: 0.5rem;
    }
  }
}

.headerTask {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 0.5em;

  svg.icon path,
  svg.icon rect {
    fill: #637381;
  }

  &.active {

    svg.icon path,
    svg.icon rect {
      fill: $main-color-green;
    }
  }
}

.rules {

  table {

    margin: 1em 0;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    /* Важно для фиксированной ширины столбцов */
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    vertical-align: top;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 300px;
  }

  th {
    background-color: #f2f2f2;
  }

  th:first-child,
  td:first-child {
    width: 40%;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 40%;
  }

  th:last-child,
  td:last-child {
    width: 20%;
  }
}